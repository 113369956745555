@tailwind base;
@tailwind components;
@tailwind utilities;

/* Genel Stil Ayarları */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.menu-preview-container {
  width: 100%;
  max-width: 700px; /* Menü genişliği */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-height: 85vh;
  overflow: auto;
}

.flex {
  display: flex;
}

.space-x-4 > * + * {
  margin-left: 16px;
}

.overflow-y-auto {
  overflow-y: auto;
}

.relative {
  position: relative;
}

button {
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
